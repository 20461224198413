import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

function BlogList() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function fetchPosts() {
      const response = await axios.get('https://l5wrjoqe5g.execute-api.us-east-1.amazonaws.com/dev/posts');
      setPosts(response.data);
    }
    fetchPosts();
  }, []);

  return (
    <div>
      <h1 className="mb-4">Blog Posts</h1>
      {posts.map(post => (
        <div key={post.postId} className="card mb-3">
          <div className="card-body">
            <h5 className="card-title">{post.title}</h5>
            <p className="card-text">{post.content.substring(0, 100)}...</p>
            <Link to={`/post/${post.postId}`} className="btn btn-primary">Read More</Link>
          </div>
        </div>
      ))}
    </div>
  );
}

export default BlogList;
