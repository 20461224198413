import React from 'react';
import { Link } from 'react-router-dom';

function NavBar() {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            {/* <Link className="navbar-brand" to="/">My Blog</Link> */}
            <div className="collapse navbar-collapse">
                <ul className="navbar-nav ml-auto">
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="/">Home</Link>
                    </li> */}
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="/new">New Post</Link>
                    </li> */}
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="/intro">Intro</Link>
                    </li> */}
                    <li className="nav-item">
                        <Link className="nav-link" to="/about">About</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/skills">Skills</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/experience">Work Experience</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/education">Education</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/contactForm">Contact Us</Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default NavBar;
