

import React from 'react';

const educationContent = [
  {
    year: "2018",
    degree: "Biomedical Engineering Degree",
    institute: "MUK",
    details: `I graduated with an Engineering degree from Makerere University, where I developed a solid understanding of both the theoretical concepts and practical applications of software development, including courses such as Advanced Algorithms and Data Structures, Web Application Development, Database Systems, Software Engineering Principles, Object-Oriented Programming, Computer Networks, Operating Systems, Mobile App Development, Artificial Intelligence, and Cybersecurity Fundamentals.`,
  },
  {
    year: "2022 - Present",
    degree: "MSc Computer Science",
    institute: "MIU",
    details: `Currently pursuing my degree in Computer Science has been like holding the key to the digital universe. I've unlocked the secrets of algorithms, mastered the art of coding, and architected the future with cutting-edge technologies. This journey through the realms of artificial intelligence, cybersecurity, and data science is equipping me to innovate and shape the world of tomorrow.`,
  },
];

function Education() {
    return (
        <div>
            <h2>Education</h2>
            {educationContent.map((edu, index) => (
              <div key={index}>
                <h3>{edu.degree}</h3>
                <p>{edu.institute} - {edu.year}</p>
                <p>{edu.details}</p>
              </div>
            ))}
        </div>
    );
}

export default Education;

