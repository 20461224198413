import React, { useState } from 'react';
import axios from 'axios';

function ContactForm() {
    const [formData, setFormData] = useState({
        messageTitle: '',
        message: '',
        email: '',
        guestName: '',
        phone: ''
    });
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const validateInput = () => {
        if (!formData.messageTitle || !formData.message || !formData.email || !formData.guestName) {
            setMessage('Please fill in all required fields.');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        if (!validateInput()) {
            return;
        }
        setLoading(true);

        try {
            const response = await axios.post('https://4sgbtl1qyj.execute-api.us-east-1.amazonaws.com/V1', formData);
            setFormData({
                messageTitle: '',
                message: '',
                email: '',
                guestName: '',
                phone: ''
            });
            
              // Check if response is successful (status code 2xx)
                if (response.status >= 200 && response.status < 300) {
                    console.log('Request successful:', response.data);
                    setMessage(`Message successfully sent`);
                } else {
                    setMessage(`Encountered error while sending  message`);
                    console.log('Request failed with status:', response.status);
                    // Assuming you want to throw an error in case of non-2xx status codes
                    throw new Error('Request failed with status ' + response.status);
                }
          
        } catch (error) {
            setMessage(`Failed to send message: ${error.message}`);
        }
        setLoading(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>Contact Us</h2>
            {loading && <p>Loading...</p>}
            {message && <div className="alert alert-info">{message}</div>}
            <div className="form-group">
                <label htmlFor="messageTitle">Message Title *</label>
                <input
                    type="text"
                    className="form-control"
                    id="messageTitle"
                    name="messageTitle"
                    value={formData.messageTitle}
                    onChange={(e) => setFormData({...formData, messageTitle: e.target.value})}
                    required
                />
            </div>
            <div className="form-group">
                <label htmlFor="message">Message *</label>
                <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    rows="3"
                    value={formData.message}
                    onChange={(e) => setFormData({...formData, message: e.target.value})}
                    required
                ></textarea>
            </div>
            <div className="form-group">
                <label htmlFor="email">Email *</label>
                <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={(e) => setFormData({...formData, email: e.target.value})}
                    required
                />
            </div>
            <div className="form-group">
                <label htmlFor="guestName">Guest Name *</label>
                <input
                    type="text"
                    className="form-control"
                    id="guestName"
                    name="guestName"
                    value={formData.guestName}
                    onChange={(e) => setFormData({...formData, guestName: e.target.value})}
                    required
                />
            </div>
            <div className="form-group">
                <label htmlFor="phone">Phone</label>
                <input
                    type="text"
                    className="form-control"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={(e) => setFormData({...formData, phone: e.target.value})}
                />
            </div>
            <button type="submit" className="btn btn-primary">Send Message</button>
        </form>
    );
}

export default ContactForm;
