import React from 'react';

function WorkExperience() {
    const experienceContent = [
      {
        year: "December 2023 - Present",
        position: "Software Engineer",
        companyName: "Scale AI",
        details: `Designed and developed highly functional Artificial Intelligence models for OpenAI (Chat GPT Improvements).`,
      },
      {
        year: "2018 - 2021",
        position: "Full-Stack Java Developer",
        companyName: "Shenuems Biomedical",
        details: `Built microservice-based applications. Developed user-friendly web applications using Java, Spring, MySQL, and React.js, resulting in a notable boost in user engagement.`,
      },
    ];

    return (
        <div>
            <h2>Work Experience</h2>
            {experienceContent.map((experience, index) => (
                <div key={index}>
                    <h3>{experience.position} - {experience.companyName}</h3>
                    <p>{experience.year}</p>
                    <p>{experience.details}</p>
                </div>
            ))}
        </div>
    );
}

export default WorkExperience;
